<template>
  <div class="diamond-app">
    <div v-if="showDiamondEffect" class="diamond-rain">
      <div v-for="diamond in diamonds" 
           :key="diamond.id" 
           class="diamond"
           :class="currentEffect"
           :style="{
             left: diamond.left + 'vw',
             top: diamond.top + 'vh',
             animationDelay: diamond.delay + 's',
             fontSize: diamond.size + 'px',
             '--angle': diamond.angle + 'deg',
             '--radius': diamond.radius + 'px',
             '--speed': diamond.speed + 'px'
           }">
        💎
      </div>
    </div>

    <div v-if="showAIChat" class="ai-chat" @click="handleAiChat">
      <div class="ai-button">
        <span class="ai-icon">🤖</span>
        <span class="ai-text">{{ aiMessage }}</span>
      </div>
    </div>
    
    <div class="mascot-container">
      <div v-motion
        :initial="{ y: 0, x: 0, rotate: 0, scale: 1 }"
        :enter="{ 
          y: [-20, 0, -10, 0],
          x: [-10, 10, -5, 0],
          rotate: [-5, 5, -3, 0],
          scale: [1, 1.1, 1, 1.05],
          transition: { 
            duration: 3000,
            repeat: Infinity,
            repeatType: 'mirror',
            ease: [0.76, 0, 0.24, 1]
          }
        }"
        class="mascot-wrapper"
        @click="handleMascotClick"
      >
        <img src="./assets/diamond-mascot.png" alt="Diamond Hands Mascot" class="mascot">
        <div v-if="bounceCount > 0" class="bounce-badge">
          {{ bounceCount }} 💎
        </div>
      </div>
    </div>
    
    <h1 class="title">The Mad Cut</h1>
    <h2 class="subtitle">$MAD Only For Hodlers</h2>
    <p class="tagline">🚀 When paper hands cry, diamond hands buy! 🙌💎</p>

    <div class="ai-agent-section">
      <button 
        @click="activateAIAgent" 
        :class="['ai-agent-button', { 'processing': isAIProcessing }]"
        :disabled="isAIProcessing"
      >
        <div class="button-content">
          <span class="agent-icon">🤖</span>
          <span class="agent-text">{{ isAIProcessing ? 'AI AGENT ANALYZING...' : 'AI AGENT' }}</span>
        </div>
        <div class="progress-bar" :style="{ width: progressWidth + '%' }"></div>
      </button>
    </div>

    <div class="meme-ticker">
      <div class="ticker-wrapper">
        <div class="ticker-content">
          <span>📈 Wife's boyfriend approved!</span>
          <span>🌙 Wen Lambo?</span>
          <span>💪 HODL or NGMI</span>
          <span>🎮 Power to the HODLers</span>
          <span>🦍 Apes together strong!</span>
          <span>😤 Sell $MAD Get MAD</span>
          <span>💎 Diamond Cuts Only</span>
          <span>🔥 Paper Hands Get Cut</span>
          <span>🧠 Smoothest Brains in Crypto</span>
        </div>
        <div class="ticker-content">
          <span>📈 Wife's boyfriend approved!</span>
          <span>🌙 Wen Lambo?</span>
          <span>💪 HODL or NGMI</span>
          <span>🎮 Power to the HODLers</span>
          <span>🦍 Apes together strong!</span>
          <span>😤 Sell $MAD Get MAD</span>
          <span>💎 Diamond Cuts Only</span>
          <span>🔥 Paper Hands Get Cut</span>
          <span>🧠 Smoothest Brains in Crypto</span>
        </div>
      </div>
    </div>

    <div class="features-grid">
      <div class="feature-card">
        <div class="icon rewards">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"/>
          </svg>
        </div>
        <h3>Diamond Rewards</h3>
        <p>HODL and earn more gems</p>
        <div class="meme-caption">*Not financial advice, ser 😉</div>
      </div>
      
      <div class="feature-card">
        <div class="icon community">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"/>
          </svg>
        </div>
        <h3>Strong Community</h3>
        <p>Built by HODLers, for HODLers</p>
        <div class="meme-caption">We eat FUD for breakfast 🍳</div>
      </div>
      
      <div class="feature-card">
        <div class="icon rocket">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <path d="M4.5 16.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09z"/>
            <path d="M12 15l-3-3a22 22 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11a22.35 22.35 0 0 1-4 2z"/>
          </svg>
        </div>
        <h3>To The Moon!</h3>
        <p>Join us on our journey</p>
        <div class="meme-caption">Trust me bro 🚀</div>
      </div>
    </div>


    <div class="university-block">
      <h2 class="university-title">💎 Diamond Hands University 🎓</h2>
      
      <div class="lessons-grid">
        <div class="lesson-card">
          <h3 class="lesson-number">Lesson #1:</h3>
          <p class="lesson-text">If you don't sell, it's not a loss 💎</p>
        </div>
        
        <div class="lesson-card">
          <h3 class="lesson-number">Lesson #2:</h3>
          <p class="lesson-text">Buy high, never sell low 📈</p>
        </div>
        
        <div class="lesson-card">
          <h3 class="lesson-number">Lesson #3:</h3>
          <p class="lesson-text">1 $DIAMOND = 1 $DIAMOND 🦍</p>
        </div>
      </div>

      <div class="university-section">
        
        <a href="https://www.youtube.com/watch?v=BfnjX88Va4Y" target="_blank" rel="noopener noreferrer" class="link-button">
          <h1>📚 Required Learning for All $MAD Holders</h1>
        </a>
      </div>

      
    </div>
    <div class="roadmap-section">
        <h2 class="section-title">🗺️ ROADMAP TO VALHALLA</h2>
        <div class="roadmap-grid">
          <div class="roadmap-card completed">
            <div class="step-label">PHASE 1 ✅</div>
            <div class="roadmap-content">
              <span class="roadmap-icon">🌐</span>
              <h3>Website</h3>
              <p>Launch the most based website in DeFi</p>
            </div>
            <div class="progress-line"></div>
          </div>
          
          <div class="roadmap-card completed">
            <div class="step-label">PHASE 2 ✅</div>
            <div class="roadmap-content">
              <span class="roadmap-icon">💬</span>
              <h3>Telegram</h3>
              <p>Create the comfiest community</p>
            </div>
            <div class="progress-line"></div>
          </div>
          
          <div class="roadmap-card completed">
            <div class="step-label">PHASE 3 ✅</div>
            <div class="roadmap-content">
              <span class="roadmap-icon">🐦</span>
              <h3>Twitter</h3>
              <p>Establish meme dominance</p>
            </div>
            <div class="progress-line"></div>
          </div>

          <div class="roadmap-card completed">
            <div class="step-label">PHASE 4 ✅</div>
            <div class="roadmap-content">
              <span class="roadmap-icon">🚀</span>
              <h3>Launch</h3>
              <p>Deploy $MAD to the world</p>
            </div>
            <div class="progress-line"></div>
          </div>

          <div class="roadmap-card">
            <div class="step-label">PHASE 5</div>
            <div class="roadmap-content">
              <span class="roadmap-icon">📊</span>
              <h3>Chart Domination</h3>
              <p>Get listed and trending on Dexscreener</p>
            </div>
            <div class="progress-line"></div>
          </div>
          
          <div class="roadmap-card">
            <div class="step-label">PHASE 6</div>
            <div class="roadmap-content">
              <span class="roadmap-icon">⚔️</span>
              <h3>Diamond Raiders</h3>
              <p>Unleash the raiders</p>
            </div>
            <div class="progress-line"></div>
          </div>
          
          <div class="roadmap-card">
            <div class="step-label">PHASE 7</div>
            <div class="roadmap-content">
              <span class="roadmap-icon">🔥</span>
              <h3>Marketing Frenzy</h3>
              <p>Launch viral marketing campaigns</p>
            </div>
            <div class="progress-line"></div>
          </div>

          <div class="roadmap-card">
            <div class="step-label">PHASE 8</div>
            <div class="roadmap-content">
              <span class="roadmap-icon">💎</span>
              <h3>Moon Mission</h3>
              <p>$MAD becomes a top 100 token</p>
            </div>
          </div>
        </div>
      </div>
    <div class="tokenomics">
      <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" 
         target="_blank" 
         class="link-button"
         @click="showRickrollMessage = true">
        <h2>📈 Whitepaper & Tokenomics for Smooth Brains</h2>
      </a>
      
      <div v-if="showRickrollMessage" class="rickroll-message">
        <p>Ser, you just got MAD rolled! Real degens don't need tokenomics 🦍</p>
        <p class="small-text">Source: Trust me bro 😎</p>
      </div>
    </div>

    <div class="cta-buttons">
      <a href="https://pump.fun/coin/F3CEjw1PcL1zxV6UcFCGgD9PxkCADpB7sTVNUANopump" target="_blank" rel="noopener noreferrer" class="btn primary">Ape In Now! 🦍</a>
      <a href="https://t.me/themadcut" target="_blank" rel="noopener noreferrer" class="btn secondary">Join the Degenerates 💎</a>
    </div>

   

    <p class="motto">💎🙌 Remember: Diamond hands never sell! 🙌💎</p>
    <p class="disclaimer">Not financial advice. DYOR. Probably nothing. 🤫</p>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useMotion } from '@vueuse/motion'

export default defineComponent({
  name: 'App',
  data() {
    return {
      bounceCount: 0,
      showAIChat: false,
      showRickrollMessage: false,
      showDiamondEffect: false,
      diamonds: [],
      currentEffect: 'rain',
      aiMessage: '',
      aiMessages: [
        { 
          calculating: "Running technical analysis...",
          result: "Charts looking absolutely bussin', fr fr! 🚀"
        },
        {
          calculating: "Analyzing market sentiment...",
          result: "Paper hands detected and eliminated from dataset! 💎"
        },
        {
          calculating: "Computing price trajectories...",
          result: "According to my calculations: Moon soon! 🌕"
        },
        {
          calculating: "Scanning blockchain activity...",
          result: "WAGMI detected with 99.9% confidence! 🎯"
        },
        {
          calculating: "Processing trading patterns...",
          result: "Bears r officially rekt! 🐻❌"
        },
        {
          calculating: "Initializing quantum prediction matrix...",
          result: "420.69x gains incoming! Trust me bro! 📈"
        },
        {
          calculating: "Running neural network simulation...",
          result: "Buy signal stronger than my CPU! 🤖"
        },
        {
          calculating: "Analyzing hodler strength...",
          result: "Diamond hands level: OVER 9000! 💪"
        },
        {
          calculating: "Calculating meme potential...",
          result: "Memes too strong - broke my circuits! 🔥"
        },
        {
          calculating: "Scanning competitor tokens...",
          result: "$MAD superiority confirmed! 👑"
        }
      ],
      isAIProcessing: false,
      progressWidth: 0,
      processingInterval: null,
    }
  },
  methods: {
    handleMascotClick() {
      this.bounceCount++
      if (this.bounceCount % 5 === 0) {
        this.triggerRandomEffect()
      }
    },
    triggerRandomEffect() {
      const effects = ['rain']
      this.currentEffect = effects[Math.floor(Math.random() * effects.length)]
      
      this.showDiamondEffect = true
      this.diamonds = Array.from({ length: 50 }, (_, i) => {
        const baseConfig = {
          id: i,
          size: Math.random() * 20 + 20
        }

        switch(this.currentEffect) {
          case 'rain':
            return {
              ...baseConfig,
              left: Math.random() * 100,
              top: -10,
              delay: Math.random() * 2
            }
        }
      })

      setTimeout(() => {
        this.showDiamondEffect = false
        this.diamonds = []
      }, 4000)
    },
    activateAIAgent() {
      if (this.isAIProcessing) return;
      
      this.isAIProcessing = true;
      this.progressWidth = 0;
      
      // Pick a random message pair
      const messageIndex = Math.floor(Math.random() * this.aiMessages.length);
      const message = this.aiMessages[messageIndex];
      
      // Show calculating message
      this.aiMessage = message.calculating;
      this.showAIChat = true;
      
      // Start progress bar animation
      this.processingInterval = setInterval(() => {
        this.progressWidth += 2;
        
        if (this.progressWidth >= 100) {
          clearInterval(this.processingInterval);
          
          // Show result message
          this.aiMessage = message.result;
          
          // Clear after showing result
          setTimeout(() => {
            this.showAIChat = false;
            this.isAIProcessing = false;
            this.progressWidth = 0;
            this.aiMessage = '';
          }, 3000);
        }
      }, 30);
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    getRandomResponse() {
      const responses = [
        "Wen moon? 🚀",
        "HODL! 💎✋",
        "This is the way! 🦍",
        "Buy high, never sell! 📈",
        "Diamond hands forever! 💎",
        "To the moon! 🌕",
        "Apes together strong! 🦍",
        "Not financial advice! 😉",
        "Trust the process! 🎯",
        "Paper hands get rekt! 📄",
        "1 $MAD = 1 $MAD 🤝",
        "LFG! 🔥",
        "WAGMI! 🌟",
        "Smooth brain time! 🧠",
        "No sell, no loss! 💪",
        "Just bought more! 💰",
        "Dips are for buying! 📉",
        "Never selling! 🔒",
        "Floor is lava! 🌋",
        "Bullish AF! 🐂"
      ];
      let lastResponse = this.aiMessage;
      let newResponse;
      do {
        newResponse = responses[Math.floor(Math.random() * responses.length)];
      } while (newResponse === lastResponse);
      return newResponse;
    },
    async handleAiChat() {
      this.aiMessage = this.getRandomResponse();
      await this.sleep(2000);
      this.aiMessage = '';
    }
  }
})
</script>

<style scoped>
.diamond-app {
  min-height: 100vh;
  background: linear-gradient(135deg, #f0f4ff 0%, #e4eaff 100%);
  padding: 2rem;
  text-align: center;
  font-family: 'Inter', sans-serif;
}

.mascot-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}

.mascot {
  width: 180px;
  height: auto;
  transition: transform 0.3s ease;
}

@media (max-width: 768px) {
  .mascot {
    width: 240px;
  }
}

.title {
  font-size: 3.5rem;
  color: #4169E1;
  font-weight: 800;
  margin: 0;
  letter-spacing: -1px;
}

.subtitle {
  font-size: 1.5rem;
  color: #666;
  margin: 1rem 0 3rem;
  font-weight: 500;
}

.tagline {
  font-size: 1.3rem;
  color: #4169E1;
  margin-bottom: 2rem;
  font-weight: 600;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto 3rem;
}

.feature-card {
  background: white;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.05);
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.icon {
  width: 60px;
  height: 60px;
  margin: 0 auto 1rem;
  background: #4169E1;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.icon svg {
  width: 32px;
  height: 32px;
}

.meme-ticker {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem 0;
}

.ticker-wrapper {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
}

.ticker-content {
  display: inline-flex;
  padding: 0;
  animation: ticker 30s linear infinite;
  white-space: nowrap;
}

.ticker-content span {
  display: inline-block;
  padding: 0 2rem;
  color: #4169E1;
  font-weight: 500;
}

@keyframes ticker {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}

.meme-caption {
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #666;
  font-style: italic;
}

.meme-section {
  max-width: 800px;
  margin: 4rem auto;
  padding: 2rem;
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.05);
}

.meme-lessons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.lesson {
  text-align: left;
  padding: 1rem;
  border-left: 4px solid #4169E1;
  background: rgba(65, 105, 225, 0.05);
  border-radius: 0 10px 10px 0;
}

.lesson h3 {
  color: #4169E1;
  margin: 0;
}

.cta-buttons {
  margin: 3rem 0;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.btn {
  padding: 1rem 2rem;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.btn:hover {
  transform: scale(1.05);
}

.primary {
  background: #4169E1;
  color: white;
}

.secondary {
  background: white;
  color: #4169E1;
  border: 2px solid #4169E1;
}

.tokenomics {
  margin: 4rem auto;
  max-width: 800px;
}

.stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
  padding: 0 20px;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat .number {
  font-size: 2.5rem;
  font-weight: 800;
  color: #4169E1;
}

.stat .label {
  color: #666;
  margin-top: 0.5rem;
}

.tokenomics-link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.tokenomics-link:hover {
  color: #4169E1;
}

.rickroll-message {
  background: linear-gradient(135deg, #ff6b6b 0%, #ff8e8e 100%);
  border-radius: 10px;
  padding: 1.5rem;
  margin: 1rem 0;
  color: white;
  font-weight: 600;
  animation: slideDown 0.3s ease-out;
}

.rickroll-message p {
  margin: 0.5rem 0;
  font-size: 1.2rem;
}

.rickroll-message .small-text {
  font-size: 0.9rem;
  opacity: 0.8;
  font-style: italic;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.motto {
  font-size: 1.2rem;
  color: #666;
  margin-top: 3rem;
}

.disclaimer {
  font-size: 0.9rem;
  color: #999;
  margin-top: 2rem;
  font-style: italic;
}

.diamond-rain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 1000;
  overflow: hidden;
}

.diamond {
  position: absolute;
  opacity: 0;
  font-size: 24px;
  z-index: 100;
}

.diamond.rain {
  animation: diamondFall 4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

@keyframes diamondFall {
  0% { transform: translateY(0) rotate(0deg) scale(0); opacity: 0; }
  10% { opacity: 1; transform: translateY(100px) rotate(180deg) scale(1); }
  90% { opacity: 1; }
  100% { transform: translateY(120vh) rotate(360deg) scale(0.5); opacity: 0; }
}

.ai-chat {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  cursor: pointer;
}

.ai-button {
  background: #4F46E5;
  color: white;
  padding: 12px 24px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
  box-shadow: 0 4px 12px rgba(79, 70, 229, 0.3);
  transition: all 0.3s ease;
}

.ai-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(79, 70, 229, 0.4);
}

.ai-icon {
  font-size: 1.2rem;
}

.ai-text {
  font-weight: 600;
  font-size: 1rem;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .ai-button {
    padding: 10px 20px;
  }
  
  .ai-text {
    font-size: 0.9rem;
  }
}

.ai-agent-section {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
  margin: 20px 0;
}

.ai-agent-button {
  position: relative;
  padding: 15px 30px;
  border: none;
  border-radius: 50px;
  background: linear-gradient(45deg, #2b5876, #4e4376);
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.ai-agent-button:not(:disabled):hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.ai-agent-button.processing {
  cursor: not-allowed;
  animation: pulse 2s infinite;
}

.button-content {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 10px;
}

.agent-icon {
  font-size: 1.3em;
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(45deg, #11998e, #38ef7d);
  opacity: 0.3;
  transition: width 0.1s linear;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.02); }
  100% { transform: scale(1); }
}

.agent-text {
  white-space: nowrap;
}

.university-block {
  background: #ffffff;
  border-radius: 24px;
  padding: 40px;
  margin: 40px auto;
  max-width: 1200px;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.15);
}

.university-title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 40px;
  font-weight: 700;
}

.lessons-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin: 0 auto 40px;
  padding: 0 20px;
}

.lesson-card {
  background: rgba(79, 70, 229, 0.05);
  border-radius: 16px;
  padding: 25px;
  text-align: center;
}

.lesson-number {
  color: #4F46E5;
  font-size: 1.4rem;
  margin-bottom: 15px;
  font-weight: 600;
}

.lesson-text {
  font-size: 1.2rem;
  color: #1a1a1a;
  margin: 0;
}

@media (max-width: 768px) {
  .university-block {
    padding: 20px;
    border-radius: 16px;
  }

  .university-title {
    font-size: 1.8rem;
    margin-bottom: 25px;
  }

  .lessons-grid {
    grid-template-columns: 1fr;
    gap: 15px;
    padding: 0;
  }

  .lesson-card {
    padding: 20px 15px;
  }

  .lesson-number {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .lesson-text {
    font-size: 1rem;
  }

  .required-learning {
    font-size: 1rem;
    padding: 12px 20px;
    margin: 15px 0;
    width: 100%;
    box-sizing: border-box;
  }
}

.roadmap-section {
  padding: 4rem 2rem;
  text-align: center;
  background: linear-gradient(135deg, #f8f9ff 0%, #f0f4ff 100%);
  border-radius: 20px;
  margin: 2rem auto;
  max-width: 1200px;
}

.roadmap-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
  position: relative;
}

.roadmap-card {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  position: relative;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0.8;
}

.roadmap-card.completed {
  opacity: 1;
  background: linear-gradient(135deg, #ffffff 0%, #f8f9ff 100%);
  border: 2px solid #4F46E5;
}

.roadmap-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
  opacity: 1;
}

.step-label {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  background: #4F46E5;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 20px;
  font-weight: bold;
  font-size: 0.9rem;
  white-space: nowrap;
}

.roadmap-content {
  margin-top: 1.5rem;
  text-align: center;
}

.roadmap-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
  display: inline-block;
}

.roadmap-content h3 {
  color: #4F46E5;
  margin: 1rem 0;
  font-size: 1.5rem;
}

.roadmap-content p {
  color: #666;
  font-size: 1rem;
  line-height: 1.5;
}

.progress-line {
  position: absolute;
  top: 50%;
  right: -1rem;
  width: 2rem;
  height: 2px;
  background: #4F46E5;
  display: none;
}

@media (min-width: 768px) {
  .progress-line {
    display: block;
  }
  
  .roadmap-card:last-child .progress-line {
    display: none;
  }
}

@media (max-width: 768px) {
  .roadmap-section {
    padding: 3rem 1rem;
  }
  
  .roadmap-grid {
    gap: 3rem;
  }
  
  .roadmap-card {
    padding: 1.5rem;
  }
}

.link-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: #4169E1;
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  border-radius: 12px;
  background: rgba(65, 105, 225, 0.1);
  transition: all 0.2s ease;
  margin: 1.5rem 0;
  border: 1px solid rgba(65, 105, 225, 0.2);
}

.link-button:hover {
  background: rgba(65, 105, 225, 0.2);
  transform: translateY(-2px);
  border-color: rgba(65, 105, 225, 0.3);
}

.link-button::after {
  content: "↗";
  font-size: 1.2rem;
  opacity: 0.8;
}

.link-button h1 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
}
</style>
